import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import OneToManyDescription from 'components/PaymentReq/OneToMany/Description/OneToManyDescription';
import { Chevron } from 'components/Shared/Icons/Icons';
import Separator from 'components/Shared/Separator/Separator';
import { breakpoints, fontSize, colors } from 'styles/cp';

export interface Props {
  description: string;
  estimatedDelivery?: string;
  shouldBeInitiallyOpen?: boolean;
}

const OneToManyMoreDetails: React.FC<Props> = ({
  description,
  estimatedDelivery,
  shouldBeInitiallyOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(shouldBeInitiallyOpen);

  return (
    <Fragment>
      {/*language=SCSS*/}
      <style jsx>{`
        .more-details {
          text-align: center;
          margin-top: 15px;
          display: none;

          @media screen and (max-width: ${breakpoints.md}) {
            display: block;
            margin-top: 0;
            background-color: ${colors.gray08};
            margin-bottom: 24px;
          }

          .header {
            cursor: pointer;
            background-color: ${colors.white};
            padding-bottom: 4px;

            .view-request-label {
              color: ${colors.lightBlue};
              font-size: ${fontSize.xs};
              user-select: none;
              font-family: AvenirNextforINTUIT-Medium;
            }

            :global(svg) {
              transition: all 0.15s linear;
              transform: rotate(${isOpen ? 0 : 180}deg);
            }
          }

          .content-holder {
            overflow: hidden;
            transition: max-height 0.35s ease;
            box-shadow: inset 0 5px 20px 0 ${colors.black + '09'};

            .content {
              margin: 16px 40px 0;
            }
          }
        }
      `}</style>

      <div className="more-details">
        <div
          className="header"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <span className="view-request-label">
            <FormattedMessage
              id="VIEW_DESCRIPTION_OF_GOODS_OR_SERVICES"
              defaultMessage="View description of goods or services"
            />
          </span>
          <Separator display="inline-block" width={5} height={5} />
          <Chevron width={12} height={16} color={colors.lightBlue} />
        </div>
        {isOpen && (
          <div className="content-holder">
            <div className="content">
              <OneToManyDescription
                description={description}
                estimatedDelivery={estimatedDelivery}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default OneToManyMoreDetails;
