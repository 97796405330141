import React from 'react';

import { ContactInfoModal } from 'components/PaymentReq/MoreDetails/PaymentRequestMoreDetails';
import { Contact } from 'components/Shared/Icons/Icons';
import { MerchantInfoProps } from 'components/Shared/Merchant/MoreInfo/MerchantMoreInfo';
import { colors } from 'styles/cp';

export interface Props extends MerchantInfoProps {
  // eslint-disable-next-line no-unused-vars
  showModal: ({
    component,
    componentProps,
  }: {
    component: React.FC;
    componentProps: Object;
  }) => void;
  companyName: string;
}

const OneToManyMerchantInfoCard: React.FC<Props> = ({
  showModal,
  companyName,
  companyEmail,
  companyPhone,
  companyWebAddr,
  companyAddress,
  number,
}) => {
  const onOpen = () => {
    showModal({
      // @ts-ignore
      component: ContactInfoModal,
      componentProps: {
        companyEmail,
        companyPhone,
        companyWebAddr,
        companyAddress,
        number,
        showCloseButton: false,
        closeOnClickOutSide: true,
      },
    });
  };

  return (
    <React.Fragment>
      <div className="container" onClick={() => onOpen()}>
        <span className="icon">
          <Contact />
        </span>
        Merchant info {companyName}
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .container {
          color: ${colors.intuit_blue};
          margin: 15px auto;
          text-align: center;
          background-color: white;
          padding: 10px 16px 10px;
          font-size: 16px;
          border-radius: 3%;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            margin-right: 8px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default OneToManyMerchantInfoCard;
